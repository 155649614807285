

































import Vue from 'vue';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';
import SleepTrackCard from '@/views/tools/sleep/components/sleep-track-card.vue';
import SleepTrackForm from '@/views/tools/sleep/components/track/sleep-track-form.vue';
import SleepTrackHistoryChart from '@/views/tools/sleep/components/sleep-track-history-chart.vue';

interface ISleepChart {
  model: ISleepTracker,
  selected: boolean,
  editing: boolean,
}

export default Vue.extend({
  name: 'sleep-track-list',
  components: {
    SleepTrackCard,
    SleepTrackForm,
    SleepTrackHistoryChart,
  },
  props: {
    values: {
      type: Array as () => Array<ISleepTracker>,
      required: true,
    },
    inline: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      loading: false,
      newSleepTrack: undefined as ISleepTracker | undefined,
      listItems: [] as ISleepChart[],
    };
  },
  computed: {
    selectedItems(): ISleepChart[] {
      return this.listItems.filter(item => item.selected);
    },
  },
  mounted () {
    this.loading = true;
    this.listItems = this.values.map((value, index) => {
      return {
        model: value,
        selected: index === 0,
        editing: false,
      };
    });
    this.loading = false;
  },
  methods: {
    onSelect(newSelection: ISleepChart[]) {
      newSelection.forEach(selectionItem => {
        this.listItems.filter(item => item.model.id === selectionItem.model.id)
          .forEach(item => { item.selected = selectionItem.selected; });
      });
    },
    onCreate() {
      this.newSleepTrack = {};
    },
    onCreateCancel() {
      this.newSleepTrack = undefined;
    },
    onEdit(sleep: ISleepChart) {
      sleep.editing = true;
    },
    onEditCancel(sleep: ISleepChart) {
      sleep.editing = false;
    },
    onUpdate() {
      this.$emit('update');
    },
  },
});
