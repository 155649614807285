var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "v-container",
        { attrs: { fluid: "", "pa-0": "" } },
        [
          _c(
            "v-layout",
            {
              attrs: {
                row: "",
                wrap: "",
                "justify-center": "",
                "align-center": "",
                "gap-xs-3": ""
              }
            },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", lg10: "" } },
                [
                  _c("sleep-track-history-chart", {
                    attrs: { dataset: _vm.listItems.toReversed() },
                    on: { "clicked-points": _vm.onSelect }
                  })
                ],
                1
              ),
              _vm.inline
                ? _c(
                    "v-flex",
                    { staticClass: "text-center", attrs: { xs12: "" } },
                    [
                      !_vm.newSleepTrack
                        ? _c(
                            "v-btn",
                            {
                              attrs: { flat: "", round: "" },
                              on: { click: _vm.onCreate }
                            },
                            [
                              _c("p", { staticClass: "starling-body" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "app.tools.sleep_tracker.actions.add_new_entry"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        : _c("sleep-track-form", {
                            attrs: {
                              value: _vm.newSleepTrack,
                              cancellable: ""
                            },
                            on: {
                              update: _vm.onUpdate,
                              cancel: _vm.onCreateCancel
                            }
                          })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.selectedItems, function(sleep) {
                return _c(
                  "v-flex",
                  { key: sleep.model.id, attrs: { xs12: "" } },
                  [
                    sleep.editing
                      ? _c("sleep-track-form", {
                          attrs: { value: sleep.model, cancellable: "" },
                          on: {
                            update: _vm.onUpdate,
                            cancel: function($event) {
                              return _vm.onEditCancel(sleep)
                            }
                          }
                        })
                      : _c("sleep-track-card", {
                          attrs: { sleep: sleep.model, showHeader: "" },
                          on: {
                            edit: function($event) {
                              return _vm.onEdit(sleep)
                            },
                            update: _vm.onUpdate
                          }
                        })
                  ],
                  1
                )
              })
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }