












import Vue from 'vue';
import actionButtons from '@/views/components/navigation/action-buttons.vue';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';

export default Vue.extend({
  name: 'sleep-track-card-delete',
  components: {
    actionButtons,
  },
  props: {
    sleep: {
      type: Object as () => ISleepTracker,
      required: true,
    },
    closeModal: {
      type: Function,
      required: true,
    },
  },
  methods: {
    actionClick() {
      this.$emit('action');
    },
  },
});
