
































import Vue from 'vue';
import store from '@/scripts/store';
import InputNoteWrapperElement from '@/views/tools/notes/input-note-wrapper-element.vue';
import { ISleepTracker } from '@/scripts/store/modules/tools/sleep/types';
import { INote } from '@/scripts/store/modules/tools/notes/types';

export default Vue.extend({
  name: 'sleep-track-card-content',
  components: {
    InputNoteWrapperElement,
  },
  props: {
    sleep: {
      type: Object as () => ISleepTracker,
      required: true,
    },
    toggleModal: {
      type: Function,
      default: (action: string) => undefined,
    },
  },
  data() {
    return {
      loading: false,
      note: {
        id: undefined,
        note: '',
        date: new Date(),
        type: 'SLEEP_TRACK',
        uen: undefined,
      } as INote,
    };
  },
  computed: {
    totalAsleep(): Object | undefined {
      const minutesAsleep = this.sleep.minutesAsleep || 0;
      const hours = Math.floor(minutesAsleep / 60);
      const minutes = minutesAsleep % 60;
      return { hours, minutes };
    },
    totalScoreStyle(): string | undefined {
      if (!this.sleep.efficiencyScore || this.sleep.efficiencyScore < 15) return;
      if (this.sleep.efficiencyScore > 85) {
        return 'width: 100%; text-align: right';
      }
      return `width: 200px; text-align: center; margin-left: calc(${this.sleep.efficiencyScore}% - 100px)`;
    },
    currentLocale() {
      return store.getters.getCurrentLocale;
    },
    abbreviatedDateFormat() {
      return store.getters.getAbbreviatedDateFormat;
    },
    fullDateFormat(): string {
      return `${this.currentLocale.formatLong?.time({ width: 'short' })}, ${this.abbreviatedDateFormat}`;
    },
  },
  mounted () {
    this.fetchNote();
  },
  methods: {
    fetchNote() {
      if (!this.sleep.noteId) return;
      this.loading = true;
      try {
        this.$store.dispatch('notes/getNote', this.sleep.noteId).then(res => {
          if (!res || res.status >= 300) throw new Error();
          this.note = { ...res };
        });
      } catch (error) {
        this.$log.debug(error);
      } finally {
        this.loading = false;
      }
    },
    onNoteUpdated(updatedNote: INote) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const state = {
        ...updatedNote,
        uen: this.sleep.uen,
      };
      try {
        this.loading = true;
        this.$store.dispatch('notes/saveNote', state).then(res => {
          this.note = res;

          if (!this.sleep.id || res.id !== this.sleep.noteId) {
            const sleep = Object.assign({}, this.sleep);
            sleep.noteId = res.id;
            this.$emit('update', sleep);
          }
        });
      } catch (error) {
        this.$log.debug(error);
      } finally {
        this.$nextTick(() => {
          this.loading = false;
        });
      }
    },
  },
});
