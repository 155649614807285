var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("canvas", {
    ref: "graph",
    style: {
      "min-height": _vm.$vuetify.breakpoint.smAndDown ? "300px" : "400px"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }