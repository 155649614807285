<template>
  <canvas :style="{ 'min-height': $vuetify.breakpoint.smAndDown ? '300px' : '400px' }" ref="graph"></canvas>
</template>

<script>
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import moment from 'moment';

export default {
  name: 'sleep-track-history-chart',
  props: {
    dataset: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartRef: false,
      chartData: undefined,
      chartConfig: undefined,
    };
  },
  computed: {
    primaryColor() {
      return getComputedStyle(document.documentElement).getPropertyValue('--StarlingPrimary1');
    },
    almostBlackColor() {
      return getComputedStyle(document.documentElement).getPropertyValue('--StarlingAlmostBlack');
    },
    darkGreyColor() {
      return getComputedStyle(document.documentElement).getPropertyValue('--StarlingDarkGrey');
    },
  },
  watch: {
    dataset: {
      immediate: true,
      handler(newValue) {
        this.formatDataset(newValue);
        if (!this.chartData) return;
        this.$nextTick(() => {
          this.renderChart(false);
        });
      },
    },
  },
  methods: {
    formatDataset(dataset) {
      if (!dataset) {
        this.chartData = undefined;
        return;
      }
      this.chartData = {
        datasets: [
          {
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: this.primaryColor,
            borderWidth: 5,
            color: this.primaryColor,
            pointBackgroundColor: this.primaryColor,
            pointRadius: 3,
            pointHoverRadius: 6,
            lineTension: 0,
            cubicInterpolationMode: 'monotone',
            definition: undefined,
            data: dataset.map(item => {
              return {
                x: item.model.refDate,
                y: item.model.efficiencyScore,
                selected: item.selected,
                model: item.model,
              };
            }),
          },
        ],
      };
    },
    renderChart(force) {
      if (!this.chartData || !this.$refs.graph) {
        if (this.chartRef) {
          this.chartRef.destroy();
        }
        return;
      }
      Chart.defaults.global.defaultFontSize = 10;
      Chart.defaults.global.defaultFontFamily = 'Open Sans,Lato,Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif';

      if (this.chartRef && this.chartRef.data && this.chartRef.data.datasets && this.chartRef.data.datasets.length > 0 && this.chartRef.data.datasets[0].name === this.chartData.name && !force) {
        return;
      }

      const that = this;

      const lineWidths = Array(101).fill(2);
      lineWidths[85] = 0; // remove default gridline on 85%

      this.chartConfig = {
        options: {
          animation: { duration: 0 },
          responsive: true,
          maintainAspectRatio: false,
          borderDash: 0,
          spanGaps: true,
          tooltips: { enabled: false },
          layout: {
            padding: {
              left: 10,
              right: 35,
              top: 30,
              bottom: 0,
            },
          },
          scales: {
            xAxes: [ {
              type: 'time',
              // distribution: 'series',
              time: {
                unit: 'day',
              },
              ticks: {
                source: 'auto',
                padding: 20,
                fontSize: 12,
                fontColor: this.darkGreyColor,
              },
              gridLines: {
                display: true,
                drawTicks: false,
                lineWidth: 0,
                zeroLineWidth: 2,
                zeroLineColor: this.almostBlackColor,
              },
            } ],
            yAxes: [ {
              ticks: {
                beginAtZero: true,
                padding: 10,
                min: 0,
                max: 100,
                stepSize: 1,
                autoSkip: false,
                fontSize: 12,
                fontColor: this.darkGreyColor,
                callback: (tick) => {
                  return (tick === 85 || tick % 25 === 0) ? tick + '%' : undefined;
                },
                major: {
                  enabled: true,
                  fontStyle: 'bold',
                  fontColor: this.primaryColor,
                },
              },
              gridLines: {
                display: true,
                drawTicks: false,
                lineWidth: lineWidths.toReversed(),
                zeroLineWidth: 2,
                zeroLineColor: this.almostBlackColor,
              },
              beforeTickToLabelConversion: (props) => {
                props._ticksToDraw = props._ticks.forEach(tick => {
                  if (tick.value === 85) tick.major = true;
                });
              },
            } ],
          },
          legend: {
            display: false,
          },
          annotation: {
            clip: false,
            annotations: [ {
              type: 'line',
              mode: 'horizontal',
              scaleID: 'y-axis-0',
              value: 85,
              borderColor: this.darkGreyColor,
              borderWidth: 2,
              borderDash: [ 5, 5 ],
              label: {
                enabled: false,
              },
            } ],
          },
          plugins: {
            datalabels: {
              listeners: {
                click: (context) => {
                  const selected = context.dataset.data[context.dataIndex].selected;
                  const date = moment(context.dataset.data[context.dataIndex].model.refDate);
                  const points = context.dataset.data.filter(point => moment(point.model.refDate).isSame(date, 'day'));

                  for (const point of points) {
                    point.selected = !selected;
                  }

                  this.$emit('clicked-points', points);
                  setTimeout(() => {
                    that.renderChart(true);
                  }, 50);
                  return true;
                },
                enter: (context) => {
                  context.chart.canvas.style.cursor = 'pointer';
                  return true;
                },
                leave: (context) => {
                  context.chart.canvas.style.cursor = 'default';
                  return true;
                },
              },
              formatter: (value, context) => {
                return value.y;
              },
              backgroundColor(context) {
                return 'white';
              },
              borderColor(context) {
                return context.dataset.borderColor;
              },
              borderWidth(context) {
                return context.dataset.data[context.dataIndex].selected ? 6 : 3;
              },
              font(context) {
                return context.dataset.data[context.dataIndex].selected ? { weight: 'bold', size: 18 } : { weight: 'normal', size: 12 };
              },
              padding(context) {
                const index = context.dataIndex;
                const value = context.dataset.data[index].y;
                if (context.dataset.data[context.dataIndex].selected) {
                  return value < 10 ? { top: 9, bottom: 7, left: 13, right: 13 } : { top: 9, bottom: 7, left: 8, right: 8 };
                }
                return value < 10 ? { top: 6, bottom: 4, left: 8, right: 8 } : { top: 6, bottom: 4, left: 5, right: 5 };
              },
              borderRadius: 50,
              offset: 8,
              textAlign: 'center',
            },
          },
        },
      };

      if (this.chartRef) {
        this.chartRef.destroy();
      }

      this.chartRef = new Chart(this.$refs.graph, {
        type: 'line',
        data: this.chartData,
        options: this.chartConfig.options,
        plugins: [ AnnotationPlugin, ChartDataLabels ],
      });
    },
  },
};
</script>
